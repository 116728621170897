import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyBH16Vo5ncQqDnH2JDvPOBL81bZxQv2F-g",
    authDomain: "vocenarpc-239119.firebaseapp.com",
    databaseURL: "https://vocenarpc-239119.firebaseio.com",
    projectId: "vocenarpc-239119",
    storageBucket: "vocenarpc-239119.appspot.com",
    messagingSenderId: "35097694314",
    appId: "1:35097694314:web:3460a22cb411cea14cfa13",
    measurementId: "G-TBC92HH4RZ"
};

export const firebaseApp = firebase.initializeApp(config);

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import headerImage from '../assets/images/header.png'

import Form from './Form/Form'

import Success from './Success/Success'

import './Application.css'

function Application() {
  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="field">
              <img align="center" alt="RPC Recicla" src={headerImage} />
            </div>

            <div className="spacer" />

            <Router>
              <Switch>
                <Route path="/cadastro">
                  <Success />
                </Route>

                <Route path="/">
                  <Form />
                </Route>
              </Switch>
            </Router>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Application

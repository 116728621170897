import InputMask from 'react-input-mask'
import axios from 'axios'
import { useCallback, useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import publicIp from "public-ip"
import moment from 'moment'

import termsOfUseDocument from '../../assets/documents/termo-de-declaracao-de-responsabilidade.pdf'

import { firebaseApp } from '../../Contants/firebase'

const API_URL =
  'https://api.atendimento.vocenarpc.com.br/v1/external/mensagens/token'

const BEARER_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJpVmZVMG9ESjZqUEo4RFRCczdVYzdLd2dDaXYxIn0.nQt4TkuaxqwyfSYj-s7EohVb9zr02VQOMdfy9tg3-UU'

const getClientIPv4 = () => publicIp.v4({
    fallbackUrls: [ "https://ifconfig.co/ip" ]
})

export default function Form() {
  const firebaseFirestore = useRef(firebaseApp.firestore()).current
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)

  const { watch, handleSubmit, register } = useForm({ mode: 'onChange' })

  const onSubmit = useCallback(
    async (data) => {
      setIsLoading(true)

      const mensagem = [
        { label: 'Nome', value: data.nome },
        { label: 'CNPJ', value: data.cnpj },
        { label: 'Cidade', value: data.cidade },
        { label: 'Bairro', value: data.bairro },
        { label: 'Endereço', value: data.endereco },
        { label: 'Horário', value: data.horario },
        { label: 'Tipo(s) de lixo', value: data.tipos_coleta },
        { label: 'Telefone', value: data.telefone },
        { label: 'Mensagem', value: data.mensagem },
      ].reduce((accumulator, field) => {
        if (field.value && field.value.length > 0) {
          return `${accumulator}\n\n${field.label}:\n${field.value}`
        }

        return accumulator
      }, 'CADASTRO DE PONTOS DE COLETA')

      try {
        await axios.post(
          API_URL,
          { mensagem },
          { headers: { Authorization: `Bearer ${BEARER_TOKEN}` } },
        )

        const firebaseMensagem = {
          ...data,
          created_at: moment().toDate(),
          ipV4: await getClientIPv4(),
          device_infos: navigator.userAgent,
        }
        await firebaseFirestore.collection('flerken-recicla-termos').add(firebaseMensagem)

        history.push('cadastro')
      } catch (err) {
        alert(
          'Não foi possível enviar os dados. Por favor, verifique sua conexão com a internet e tente novamente.',
        )
      }

      setIsLoading(false)
    },
    [history, firebaseFirestore],
  )

  const telefone = watch('telefone', '')

  const [phoneMask, setPhoneMask] = useState('(99) 9999-99999')

  useEffect(() => {
    if (telefone.replace(/\D/g, '').length === 11) {
      setPhoneMask('(99) 99999-9999')
    } else {
      setPhoneMask('(99) 9999-99999')
    }
  }, [setPhoneMask, telefone])

  return (
    <div>
      <h1 className="title has-text-centered">CADASTRO DE PONTOS DE COLETA</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <label htmlFor="nome" className="label">
            Nome do estabelecimento *
          </label>

          <div className="control">
            <input
              className="input"
              id="nome"
              name="nome"
              placeholder="Nome do estabelecimento"
              required
              type="text"
              {...register('nome')}
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="cnpj" className="label">
            CNPJ *
          </label>

          <div className="control">
            <InputMask
              mask="99.999.999/9999-99"
              maskChar=" "
              className="input"
              id="cnpj"
              name="cnpj"
              placeholder="CNPJ do estabelecimento"
              required
              type="text"
              {...register('cnpj')}
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="cidade" className="label">
            Cidade *
          </label>

          <div className="control">
            <input
              className="input"
              id="cidade"
              name="cidade"
              placeholder="Cidade do estabelecimento"
              required
              type="text"
              {...register('cidade')}
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="bairro" className="label">
            Bairro *
          </label>

          <div className="control">
            <input
              className="input"
              id="bairro"
              name="bairro"
              placeholder="Bairro do estabelecimento"
              required
              type="text"
              {...register('bairro')}
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="endereco" className="label">
            Endereço *
          </label>

          <div className="control">
            <input
              className="input"
              id="endereco"
              name="endereco"
              placeholder="Endereço do estabelecimento"
              required
              type="text"
              {...register('endereco')}
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="horario" className="label">
            Horário de funcionamento *
          </label>

          <div className="control">
            <input
              className="input"
              id="horario"
              name="horario"
              placeholder="Horário do estabelecimento"
              required
              type="text"
              {...register('horario')}
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="tipos_coleta" className="label">
            Tipo(s) de lixo para coleta *
          </label>

          <div className="control">
            <input
              className="input"
              id="tipos_coleta"
              name="tipos_coleta"
              placeholder="Tipo(s) de lixo para coleta"
              required
              {...register('tipos_coleta')}
              type="text"
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="telefone" className="label">
            Telefone *
          </label>

          <div className="control">
            <InputMask
              mask={phoneMask}
              maskChar=" "
              className="input"
              id="telefone"
              name="telefone"
              placeholder="Telefone do estabelecimento"
              required
              type="text"
              {...register('telefone')}
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="mensagem" className="label">
            Observação
          </label>

          <div className="control">
            <textarea
              className="textarea"
              id="mensagem"
              maxLength="20000"
              name="mensagem"
              placeholder="Deixe sua mensagem"
              {...register('mensagem')}
            />
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <button
              className="button is-link is-medium"
              disabled={isLoading}
              type="submit"
            >
              INDICAR PONTO DE COLETA
            </button>
          </div>
        </div>

        <br />

        <div>
          <input
            id="termos_de_uso"
            required
            type="checkbox"
            valor="termos_de_uso"
            {...register('termos_de_uso')}
          />{' '}
          <label htmlFor="termos_de_uso">
            Declaro que li e aceito o termo de uso.{' '}
            <a rel="noopener noreferrer" href={termsOfUseDocument} target="_blank">
              Clique aqui para ler o termo de uso.
            </a>
          </label>
        </div>
      </form>
    </div>
  )
}



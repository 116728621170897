import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <div>
      <h1 className="has-text-centered title">PONTO DE COLETA EM ANÁLISE</h1>

      <h3 className="has-text-centered title">
        Em breve, entraremos em contato
      </h3>

      <div className="has-text-centered">
        <Link className="button is-link is-medium" to="/">
          INDICAR OUTRO PONTO DE COLETA
        </Link>
      </div>
    </div>
  )
}
